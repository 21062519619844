import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import 'moment/locale/fr';
import WelloAvatar from '../../Shared/WelloAvatar';
import QuotationTransport from './QuotationTransport';
import QuotationDefault from './QuotationDefault';
import { isServiceTransport } from '../../../Acl/Controls';
import QuotationOther from './QuotationOther';
import Routes from '../../../Routing/Routes';
import { isOpenedAtTransportDate } from '../../../Services/DataHelper';

type Props = {
  organisation: Object,
  lead: Object,
  onNextClick: Function,
  setTripPriceInfo: Function,
  openedAt: Object,
  isOtherCategory: Boolean,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: '25px',
    height: '25px',
    display: 'inline-block',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formLegend: {
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  buttonWrapper: {
    marginRight: theme.spacing(2),
    position: 'relative',
  },
}));

const QuotationStep0 = ({
  organisation,
  lead,
  onNextClick,
  setTripPriceInfo,
  openedAt,
  isOtherCategory,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const isSLA = Routes.currentLeague().namespace === 'SLA';
  const [stepLead, setStepLead] = React.useState(lead);

  const { service_id: serviceId } = match.params;

  const [services, activeServiceKey, currentService, isTransport] = React.useMemo(() => {
    const svcs = organisation?.services?.map(os => os.service);
    const selectedService = svcs.find(service => service.id === stepLead.selectedServiceId);
    return [
      svcs,
      selectedService
        ? `${selectedService.label
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '_')}.`
        : '',
      selectedService,
      isServiceTransport(selectedService),
    ];
  }, [organisation, stepLead.selectedServiceId]);

  const hasServiceToOffer = serviceId || services?.length > 0;
  const noServiceSelected = !serviceId && !stepLead.origin_lead_id && services.length > 0;
  const serviceSelected = stepLead.selectedServiceId;
  const isTransportReturnOpened =
    stepLead.returnDate &&
    stepLead.returnTime &&
    isOpenedAtTransportDate(stepLead.departureDate, stepLead.departureTime, openedAt) &&
    isOpenedAtTransportDate(stepLead.returnDate, stepLead.returnTime, openedAt);

  const isTransportDepatureOpened =
    stepLead.departureDate &&
    stepLead.departureTime &&
    isOpenedAtTransportDate(stepLead.departureDate, stepLead.departureTime, openedAt);

  const isValidFormStep = () => {
    if (stepLead.selectedServiceId && isTransport) {
      if (!stepLead.transportDateIsValid) {
        return false;
      }
      if (
        stepLead.roundTrip &&
        (!stepLead.returnDate || !stepLead.returnTime || !isTransportReturnOpened)
      )
        return false;
      return (
        stepLead.departureFrom?.formattedAddress &&
        stepLead.arrivalTo?.formattedAddress &&
        stepLead.passagerCount > 0 &&
        isTransportDepatureOpened
      );
    }

    if (isOtherCategory && isSLA) {
      return (
        (stepLead.helperType !== 'other' &&
          stepLead.situation &&
          stepLead.age &&
          stepLead.need &&
          stepLead.helperType) ||
        stepLead.helperType === 'other'
      );
    }
    // Check if orga offer this service
    return Boolean(stepLead.selectedServiceId && currentService);
  };

  return (
    <>
      <Typography variant="h5" component="p" align="center" className={classes.formTitle}>
        {t(`APP.QUOTATION.STEP_0.${activeServiceKey}TITLE`, t(`APP.QUOTATION.STEP_0.TITLE`))}
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <WelloAvatar
          avatarUrl={organisation?.holding?.cover_url || organisation?.banner_url}
          avatarClass={classes.avatar}
          size={36}
        />
        <Typography variant="h6" component="h1" style={{ display: 'inline-block' }}>
          {`${organisation?.holding?.name} - ${organisation?.name}`}
        </Typography>
      </Grid>
      {!serviceId && (!services || services.length === 0) && (
        <Grid container justifyContent="center" alignItems="center" className={classes.formControl}>
          {t('APP.QUOTATION.SERVICES.NO_SERVICE')}
        </Grid>
      )}
      {hasServiceToOffer && (
        <>
          {noServiceSelected && (
            <>
              <FormControl component="fieldset" className={classes.formControl} fullWidth>
                <FormLabel component="legend" className={classes.formLegend}>
                  {t('APP.QUOTATION.SERVICES.SELECT')}
                </FormLabel>
                <List>
                  <ListItemText className={classes.listItemSelect}>
                    <Select
                      fullWidth
                      displayEmpty
                      value={stepLead.selectedServiceId}
                      onChange={event =>
                        setStepLead({ ...stepLead, selectedServiceId: event.target.value })
                      }
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          className={classes.inputSelect}
                          name="service"
                          id="service-simple"
                        />
                      }
                      variant="outlined"
                    >
                      {services.map(serv => (
                        <MenuItem key={serv.label} value={serv.id}>
                          {serv.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </ListItemText>
                </List>
              </FormControl>
            </>
          )}
          {serviceSelected && isTransport && (
            <QuotationTransport
              lead={stepLead}
              setLead={setStepLead}
              setTripPriceInfo={setTripPriceInfo}
              openedAt={openedAt}
              service={currentService}
            />
          )}
          {!isTransport && !isOtherCategory && (
            <QuotationDefault
              lead={stepLead}
              setLead={setStepLead}
              activeServiceKey={activeServiceKey}
            />
          )}
          {isOtherCategory && <QuotationOther lead={stepLead} setLead={setStepLead} />}
        </>
      )}
      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <Button onClick={() => history.goBack()}>{t('CANCEL')}</Button>
        </div>
        <Button
          variant="contained"
          disabled={!isValidFormStep()}
          color="primary"
          onClick={() => onNextClick(stepLead)}
        >
          {t('NEXT')}
        </Button>
      </div>
    </>
  );
};

export default QuotationStep0;
