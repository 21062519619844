import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router';

import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { green, grey, red, orange } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Avatar, Chip } from '@material-ui/core';

import { getCapitalizedName } from '../../../Services/DataHelper';
import moment from 'moment';
import { hex2rgba } from '../../../Services/DataHelper';
import { checkHostConstraint, HOSTS_NAMES } from '../../../Routing/HostConstraint';

const useStyles = makeStyles(theme => ({
  namesText: {
    fontWeight: '700',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    lineBreak: 'anywhere',
  },
  greyIcon: {
    color: grey[600],
  },
  cell: {
    padding: theme.spacing(1),
  },
  helperType: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 0.5),
  },
  orgas: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  cursor: {
    cursor: 'pointer',
  },
}));

type Props = {
  helper: Object,
};

const RowHelper = ({ helper }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { league_id: leagueId } = match.params;

  const renderLeadLeagueStatus = (icon, color) => (
    <Tooltip title={t(`APP.LEADS.LEAGUE.STATUS.${helper?.last_lead?.league_status}`)}>
      <Chip
        size="small"
        label={helper?.last_lead?.service_label || t(`APP.LEADS.${helper?.last_lead?.type}`)}
        icon={icon}
        style={{
          backgroundColor: hex2rgba(color, 0.2),
          border: `solid ${color}`,
        }}
      />
    </Tooltip>
  );

  return (
    <React.Fragment>
      <TableRow
        key={helper?.id}
        hover
        onClick={() =>
          history.push(`/leagues/${leagueId}/helpers/${helper.id}`, { from: 'league-helpers' })
        }
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        className={classes.cursor}
      >
        {/* helper name */}
        <TableCell className={classes.cell} style={{ width: '20%', paddingLeft: 16 }}>
          <Typography className={classes.namesText}>
            {getCapitalizedName(helper?.first_name, helper?.last_name)}
          </Typography>
        </TableCell>

        {/* helper orgas */}
        <TableCell className={classes.cell} style={{ width: '15%' }}>
          <Tooltip title={helper.orgas}>
            <Typography className={classes.orgas}>{helper.orgas}</Typography>
          </Tooltip>
        </TableCell>

        {/* helper league status */}
        <TableCell align="center" className={classes.cell} style={{ width: '15%' }}>
          {helper.league_status}
        </TableCell>

        {/* lead league status */}
        <TableCell align="center" className={classes.cell} style={{ width: '23%' }}>
          {helper?.last_lead?.league_status === 'new' &&
            renderLeadLeagueStatus(
              <HelpIcon fontSize="small" style={{ color: grey[500] }} />,
              grey[500],
            )}
          {helper?.last_lead?.league_status === 'pending' &&
            renderLeadLeagueStatus(
              <Avatar style={{ backgroundColor: orange[500] }}>
                <HourglassEmptyIcon fontSize="small" style={{ color: 'white', padding: 4 }} />
              </Avatar>,
              orange[500],
            )}
          {helper?.last_lead?.league_status === 'validated' &&
            renderLeadLeagueStatus(
              <CheckCircleIcon fontSize="small" style={{ color: green[500] }} />,
              green[500],
            )}
          {helper?.last_lead?.league_status === 'declined' &&
            renderLeadLeagueStatus(
              <CancelIcon fontSize="small" style={{ color: red[500] }} />,
              red[500],
            )}
          {helper?.last_lead?.league_status === 'transfered' && (
            <Tooltip title={t(`APP.LEADS.LEAGUE.STATUS.${helper?.last_lead?.league_status}`)}>
              <AirlineStopsIcon className={classes.greyIcon} />
            </Tooltip>
          )}
          {helper?.last_lead?.league_status === 'in_organisation' && (
            <Tooltip title={t(`APP.LEADS.LEAGUE.STATUS.${helper?.last_lead?.league_status}`)}>
              <ArrowRightAltIcon className={classes.greyIcon} />
            </Tooltip>
          )}
        </TableCell>

        {/* lead orga status */}
        <TableCell align="center" className={classes.cell} style={{ width: '22%' }}>
          {helper?.last_lead &&
            (helper?.last_lead?.league_status === 'transfered' ||
              helper?.last_lead?.league_status === 'in_organisation') && (
              <>
                {helper?.last_lead?.responsible_id
                  ? renderLeadLeagueStatus(
                      <CheckCircleIcon fontSize="small" style={{ color: green[500] }} />,
                      green[500],
                    )
                  : renderLeadLeagueStatus(
                      <HelpIcon fontSize="small" style={{ color: grey[500] }} />,
                      grey[500],
                    )}
              </>
            )}
        </TableCell>

        {/* lead date */}
        <TableCell className={classes.cell} style={{ width: '5%', paddingRight: 16 }}>
          {helper?.last_lead?.created_at &&
            moment(helper?.last_lead?.created_at).format('DD/MM/YY')}
        </TableCell>

        {/* transport date */}
        {checkHostConstraint(HOSTS_NAMES.mormal) && (
          <TableCell className={classes.cell} style={{ width: '5%', paddingRight: 16 }}>
            {helper?.last_lead?.departure_date &&
              moment(helper?.last_lead?.departure_date).format('DD/MM/YY')}
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default RowHelper;
