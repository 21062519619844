import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import { isMobile } from '../../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  section: {
    // margin: theme.spacing(4, 0),
  },
  sectionTitle: {
    margin: theme.spacing(1),
  },
  slider: {
    '&>*': {
      margin: 0,
      '&>*': {
        margin: 0,
      },
    },
    '& .slick-dots': {
      position: 'static',
    },
  },
  hiddenSlick: {
    '& .slick-prev': { visibility: 'hidden' },
    '&>*': {
      margin: 0,
      '&>*': {
        margin: 0,
      },
    },
  },
}));

type Props = {
  children: Object,
  title: String,
  slides: Array,
  isInfinite: Boolean,
};

const CardSliderSection = ({ children, title, slides, isInfinite }: Props) => {
  const classes = useStyles();

  const settings = {
    arrows: !isMobile,
    centerMode: false,
    infinite: isInfinite,
    slidesToShow: slides ? slides[0] : 4,
    slidesToScroll: 1,
    speed: 500,
    dots: isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slides ? slides[1] : 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: slides ? slides[2] : 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slides ? slides[3] : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid className={isInfinite ? classes.hiddenSlick : classes.section}>
      {title && (
        <Typography variant="h4" component="h3" className={classes.sectionTitle}>
          {title}
        </Typography>
      )}
      <Slider {...settings} className={classes.slider}>
        {children}
      </Slider>
    </Grid>
  );
};

export default CardSliderSection;
