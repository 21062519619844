import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Stepper from '../../../Components/Organisations/Quotation/QuotationStepper';
import QuotationStep0 from '../../../Components/Organisations/Quotation/QuotationStep0';
import QuotationStep1 from '../../../Components/Organisations/Quotation/QuotationStep1';
import QuotationStep2 from '../../../Components/Organisations/Quotation/QuotationStep2';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import {
  isVisitor,
  isServiceTransport,
  isServiceOther,
  isAssociation,
  isSap,
  getRoleInOrga,
} from '../../../Acl/Controls';
import { formatHours, coordinatesArrayToString } from '../../../Services/DataHelper';
import { serializeDateFromDatePicker } from '../../../Services/FormHelpers';
import LeadsActions from '../../../Redux/LeadsRedux';
import { ASK_FOR } from '../../../Services/LeadHelper';
import ServiceDetail from '../../../Components/Organisations/Shared/ServiceDetail';
import { HELPER_TYPES } from '../../../Services/ServiceHelper';
import Routes from '../../../Routing/Routes';
import { CheckRoleRule } from '../../../Acl/Rules';
import { Alert } from '@mui/material';
import NoMatch404 from '../../NoMatch404';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  layoutContainer: {
    ...LayoutStyles.fullWidthLayout,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  verticalContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textCenteredWithPadding: {
    color: theme.palette.common.white,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  divider: {
    margin: theme.spacing(1, 3.5),
  },
}));

const Quotation = ({ organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { service_id: serviceId, city, lead_id: leadId } = match.params;
  const leadState = useSelector(state => state.leads.lead);
  const leadEdit = leadId == leadState?.id ? leadState : null;

  const requireSignIn = Boolean(
    organisation?.feature_flip?.signin_required_for_demands && isVisitor(organisation.role),
  );
  const currentUser = useSelector(state => state.auth.currentUser);
  const form = useSelector(state => state.users.form);
  const isSLA = Routes.currentLeague().namespace === 'SLA';
  const [activeStep, setActiveStep] = React.useState(0);
  const [tripPriceInfo, setTripPriceInfo] = React.useState(null);

  const getMinDepartureDatetime = () => {
    if (!isAssociation(organisation) && !isSap(organisation)) {
      const currentDate = new Date();
      const minDefaultDate = new Date();
      minDefaultDate.setHours(currentDate.getHours() + 2);
      return moment(minDefaultDate);
    }

    const now = new Date();
    const minDate = new Date(now);
    minDate.setDate(minDate.getDate() + 2);

    const nowDay = now.getDay();
    if (nowDay === 0 || nowDay === 6) {
      const daysToWednesday = (3 + 7 - nowDay) % 7;
      minDate.setDate(now.getDate() + daysToWednesday);
      minDate.setHours(0, 0, 0, 0);
    }

    return moment(minDate);
  };

  const [lead, setLead] = React.useState({
    // Step 0
    description: form?.reason || '',
    recurrence: '',
    meta: { ville: city },
    helperType: HELPER_TYPES[0].slug,
    situation: '',
    age: '',
    need: '',
    selectedServiceId: serviceId ? parseInt(serviceId, 10) : null,
    // transport
    roundTrip: false,
    returnDate: null,
    returnTime: null,
    reason: '',
    passagerCount: 1,
    needAccompagnement: false,
    departureFrom: form?.addressFrom || '',
    arrivalTo: form?.addressTo || '',
    departureDate: form?.transportDate || null,
    departureTime: form?.transportTime || null,
    minDepartureDatetime: getMinDepartureDatetime(),
    transportDateIsValid: true,
    // Step 1
    email: currentUser?.email || '',
    phone: currentUser?.phone || '',
    countryCode: currentUser?.country_code || 'fr',
    // Step 2
    firstName: currentUser ? currentUser.first_name : '',
    lastName: currentUser ? currentUser.last_name : '',
    gender: currentUser?.gender || '',
    birthday: currentUser ? new Date(currentUser.birthday) : null,
    helperPathology: currentUser?.pathology || '',
    helpedFirstName: form?.helped_first_name || '',
    helpedLastName: form?.helped_last_name || '',
    helpedPathology: '',
    helpedId: null,
    formattedAddress: currentUser?.formatted_address || '',
    askFor: form?.ask_for || ASK_FOR.ME,
    latitude: currentUser?.coordinates ? currentUser.coordinates.latitude : 0,
    longitude: currentUser?.coordinates ? currentUser.coordinates.longitude : 0,
    locality: currentUser?.locality || '',
    addressSupplement: currentUser?.address_supplement || '',
    moreAbout: '',
    relationShip: '',
    // Error
    phoneError: null,
  });

  React.useEffect(() => {
    if (!leadId) {
      return;
    }

    dispatch(LeadsActions.getFamilyLeadRequest(currentUser.id, leadId));
  }, [leadId]);

  React.useEffect(() => {
    if (!leadEdit) {
      return;
    }

    const departureDateMoment = leadEdit?.meta?.departure_date
      ? moment(leadEdit?.meta?.departure_date, 'YYYY-MM-DD HH:mm:ss')
      : null;
    const returnDateMoment = leadEdit?.meta?.return_date
      ? moment(leadEdit?.meta?.return_date, 'YYYY-MM-DD HH:mm:ss')
      : null;

    setLead({
      // Step 0
      origin_lead_id: parseInt(leadId),
      description: leadEdit?.description || '',
      recurrence: '',
      meta: leadEdit?.meta,
      helperType: HELPER_TYPES[0].slug,
      situation: '',
      age: leadEdit?.age,
      need: '',
      selectedServiceId: leadEdit?.service?.id,
      // transport
      roundTrip: leadEdit?.meta?.round_trip,
      reason: '',
      passagerCount: leadEdit?.meta?.passager_count,
      needAccompagnement: leadEdit?.meta?.need_accompagnement,
      departureFrom: {
        latitude: leadEdit?.departure_from?.latitude,
        longitude: leadEdit?.departure_from?.longitude,
        formattedAddress: leadEdit?.meta?.departureFormattedAddress,
      },
      arrivalTo: {
        latitude: leadEdit?.arrival_to?.latitude,
        longitude: leadEdit?.arrival_to?.longitude,
        formattedAddress: leadEdit?.meta?.arrivalFormattedAddress,
      },
      departureDate: departureDateMoment,
      departureTime: departureDateMoment,
      returnDate: returnDateMoment,
      returnTime: returnDateMoment,
      minDepartureDatetime: getMinDepartureDatetime(),
      transportDateIsValid: true,
      // Step 1
      email: leadEdit?.email || '',
      phone: leadEdit?.phone || '',
      countryCode: currentUser?.country_code || 'fr',
      // Step 2
      firstName: leadEdit?.helper_first_name || '',
      lastName: leadEdit?.helper_last_name || '',
      gender: currentUser?.gender || '',
      birthday: leadEdit?.birthday,
      helperPathology: leadEdit?.helper_pathology || '',
      helpedFirstName: leadEdit?.helped_first_name || '',
      helpedLastName: leadEdit?.helped_last_name || '',
      helpedPathology: leadEdit?.helped_pathology || '',
      helpedId: leadEdit?.helped?.id,
      formattedAddress: leadEdit?.formatted_address || '',
      askFor: leadEdit?.ask_for || ASK_FOR.ME,
      latitude: currentUser?.coordinates ? currentUser.coordinates.latitude : 0,
      longitude: currentUser?.coordinates ? currentUser.coordinates.longitude : 0,
      locality: leadEdit?.locality || '',
      addressSupplement: leadEdit?.addressSupplement || '',
      moreAbout: '',
      relationShip: '',
      // Error
      phoneError: null,
    });
  }, [leadEdit]);

  const [preSelectedService, isTransport] = React.useMemo(() => {
    const svcs = organisation?.services?.map(os => os.service);
    if (organisation && organisation.services && lead.selectedServiceId) {
      const selectedService = svcs.find(service => service.id === lead.selectedServiceId);
      return [selectedService, isServiceTransport(selectedService)];
    }
    return [false, false];
  }, [organisation, lead.selectedServiceId]);

  if (
    !preSelectedService?.allow_public &&
    !CheckRoleRule(
      getRoleInOrga(currentUser, organisation.holding_slug, organisation.slug),
      'organisation:configure',
    )
  ) {
    return NoMatch404();
  }

  const openedAt = formatHours(preSelectedService.opened_at);
  const isOtherCategory = isServiceOther(preSelectedService);

  const onNextClick = stepLead => {
    setLead({ ...lead, ...stepLead });
    setActiveStep(activeStep + 1);
  };

  const onBackClick = stepLead => {
    setLead({ ...lead, ...stepLead });
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = (event, stepLead) => {
    event.preventDefault();
    const data = { ...lead, ...stepLead };
    const leadToCreate = {
      origin_lead_id: parseInt(leadId),
      organisation_id: organisation.id,
      ask_for: data.askFor,
      latitude: data.latitude,
      longitude: data.longitude,
      locality: data.locality,
      service_id: data.selectedServiceId,
      description: data.description,
      recurrence: data.recurrence,
      meta: data.meta,
      phone: data.phone,
      country_code: data.countryCode,
      email: data.email.toLowerCase()?.trim(),
      gender: data.gender,
      helper_first_name: data.firstName,
      helper_last_name: data.lastName,
      helper_pathology: data.askFor === ASK_FOR.ME ? data.helperPathology : '',
      birthday: serializeDateFromDatePicker(data.birthday),
      helped_first_name: data.askFor === ASK_FOR.OTHER ? data.helpedFirstName : '',
      helped_last_name: data.askFor === ASK_FOR.OTHER ? data.helpedLastName : '',
      helped_pathology: data.askFor === ASK_FOR.OTHER ? data.helpedPathology : '',
      helped_id: data.askFor === ASK_FOR.OTHER ? data.helpedId : null,
      formatted_address: data.formattedAddress || '',
      departure_from: data.departureFrom,
      arrival_to: data.arrivalTo,
      address_supplement: data.addressSupplement || '',
      clone_to_date: data.cloneToDate?.map(date => {
        return {
          departure: `${moment(date.departureDate).format('YYYY-MM-DD')} ${moment(
            date.departureTime,
          ).format('H:mm:ss')}`,
          return: date.roundTrip
            ? `${moment(date.returnDate).format('YYYY-MM-DD')} ${moment(date.returnTime).format(
                'H:mm:ss',
              )}`
            : null,
          round_trip: !!date.roundTrip,
        };
      }),
    };
    if (preSelectedService && isTransport) {
      const departureDateTime = `${moment(data.departureDate).format('YYYY-MM-DD')} ${moment(
        data.departureTime,
      ).format('H:mm:ss')}`;

      leadToCreate.meta = {
        departure_date: departureDateTime,
        departureFormattedAddress: data.departureFrom.formattedAddress,
        arrivalFormattedAddress: data.arrivalTo.formattedAddress,
        departure_locality: data.departureFrom.locality,
        arrival_locality: data.arrivalTo.locality,
        round_trip: data.roundTrip,
        reason: data.reason,
        passager_count: data.passagerCount,
        need_accompagnement: data.needAccompagnement,
        duration: data.duration,
        estimatedPrice: tripPriceInfo.estimatedPrice,
        trip_duration: tripPriceInfo.duration,
        distance: tripPriceInfo.distance,
        geolocs: coordinatesArrayToString(tripPriceInfo.geolocs),
      };
      if (data.roundTrip) {
        const returnDateTime = `${moment(data.returnDate).format('YYYY-MM-DD')} ${moment(
          data.returnTime,
        ).format('H:mm:ss')}`;
        leadToCreate.meta.return_date = returnDateTime;
      }
    }

    if (isSLA && leadToCreate.helperType !== 'other') {
      leadToCreate.meta = {
        ...data.meta,
        helper_type: data.helperType,
        helper_situation: data.situation,
        helper_age: data.age,
        helper_needs: data.need,
      };
    }

    dispatch(
      LeadsActions.createQualifiedLeadRequest(
        organisation.holding_slug,
        organisation.slug,
        leadToCreate,
      ),
    );

    if (!currentUser) {
      window.dataLayer.push({
        event: 'gtm_create_account',
        origin: 'service',
        orga_id: organisation?.id,
      });
    }
  };

  if (leadId && !leadEdit) {
    return null;
  }

  if (
    leadId &&
    leadEdit &&
    !CheckRoleRule(
      getRoleInOrga(currentUser, organisation.holding_slug, organisation.slug),
      'leads:edit',
      { userId: currentUser?.id, leadOwnerId: leadEdit?.helper?.id },
    )
  ) {
    return null;
  }

  if (requireSignIn)
    return <Redirect to={`/signin/${organisation.holding_slug}/${organisation.slug}/required`} />;

  return (
    <>
      <Stepper
        organisation={organisation}
        activeStep={activeStep}
        isOtherCategory={isOtherCategory}
      />

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Grid item md={10} xs={12} style={{ margin: 'auto' }} className={classes.layoutContainer}>
          <Paper className={classes.paper}>
            {leadEdit && (
              <Box pb={3}>
                <Alert severity={'warning'}>{t('APP.QUOTATION.SERVICES.EDIT_WARNING')}</Alert>
              </Box>
            )}
            <Grid container className={classes.container}>
              <Grid item md={preSelectedService ? 8 : 12} xs={12}>
                {activeStep === 0 && (
                  <QuotationStep0
                    organisation={organisation}
                    lead={lead}
                    setLead={setLead}
                    onNextClick={onNextClick}
                    setTripPriceInfo={setTripPriceInfo}
                    openedAt={openedAt}
                    isOtherCategory={isOtherCategory}
                  />
                )}
                {activeStep === 1 && (
                  <QuotationStep1
                    lead={lead}
                    setLead={setLead}
                    currentUser={currentUser}
                    onBackClick={onBackClick}
                    onNextClick={onNextClick}
                    onSubmitClick={handleSubmit}
                    isOtherCategory={isOtherCategory}
                  />
                )}
                {activeStep === 2 && (
                  <QuotationStep2
                    lead={lead}
                    setLead={setLead}
                    currentUser={currentUser}
                    isTransport={isTransport}
                    onBackClick={onBackClick}
                    onSubmitClick={handleSubmit}
                  />
                )}
              </Grid>
              <Hidden only={['xs']}>
                <Divider md={1} orientation="vertical" flexItem className={classes.divider} />
              </Hidden>
              {preSelectedService && (
                <Grid item md={3} xs={12}>
                  <ServiceDetail
                    service={preSelectedService}
                    isTransport={isTransport}
                    tripPriceInfo={tripPriceInfo}
                    openedAt={openedAt}
                    showHeader
                    organisation={organisation}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </form>
    </>
  );
};

export default Quotation;
