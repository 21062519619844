import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UserForm from './UserForm';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import UserActions from '../../Redux/UsersRedux';
import { serializeDateFromDatePicker } from '../../Services/FormHelpers';
import { standardizeWordCase } from '../../Services/DataHelper';
import { isPrimary } from '../../Acl/Controls';
import Routes from '../../Routing/Routes';

type Props = {
  organisation: Object,
  holding: Object,
};

const useStyles = makeStyles(theme => ({
  layout: { ...LayoutStyles.centered600Layout, marginTop: 0 },
  paper: {
    padding: theme.spacing(3),
  },
}));

const UserSetup = ({ organisation, holding }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { family_slug: familySlug } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const isUpdating = useSelector(state => state.users.isFetching.updateUser);
  const { t } = useTranslation();
  const [updateSuccessRedirect, setSuccess] = React.useState(false);
  const [user, setUser] = React.useState({
    gender: currentUser?.gender || 'female',
    firstName: currentUser?.first_name || '',
    lastName: currentUser?.last_name || '',
    phone: currentUser?.phone || '',
    country_code: currentUser?.country_code || 'fr',
    locality: currentUser?.locality || '',
    birthday: new Date(currentUser?.birthday || (1970, 1, 1)),
    formattedAddress: currentUser?.formatted_address || '',
    addressSupplement: currentUser?.address_supplement || '',
    description:
      currentUser?.description ||
      t('APP.USER.TEXT_FIELD.DEFAULT_VALUE.DESCRIPTION', {
        league: t(`${Routes.currentNamespace()}.SEO.SITENAME`),
      }),
    pathology: currentUser?.pathology || '',
    latitude: currentUser?.latitude || '',
    longitude: currentUser?.longitude || '',
    avatarPreview: currentUser?.avatar_url,
    avatar: null,
  });

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      UserActions.updateUserRequest(
        currentUser.id,
        {
          first_name: standardizeWordCase(user.firstName),
          last_name: standardizeWordCase(user.lastName),
          phone: user.phone,
          country_code: user.country_code,
          description: user.description,
          pathology: user.pathology,
          gender: user.gender,
          birthday: serializeDateFromDatePicker(user.birthday),
          locality: user.locality,
          formatted_address: user.formattedAddress,
          latitude: user.latitude,
          longitude: user.longitude,
          address_supplement: user.addressSupplement,
        },
        user.avatar && user.avatar.size > 0 ? user.avatar : null,
        () => {
          setSuccess(true);
        },
      ),
    );
  };

  return (
    <Grid container className={classes.layout}>
      {!updateSuccessRedirect && (
        <Paper className={classes.paper}>
          <Typography align="center" component="h1" variant="h5">
            {t('APP.USER.INFO_FORM.TITLE')}
          </Typography>
          <UserForm
            onSubmit={handleSubmit}
            user={user}
            setUser={setUser}
            isUpdating={isUpdating}
            hideProfilParams
            hideAdvancedParams
            hideEmailParams
          />
        </Paper>
      )}
      {updateSuccessRedirect && familySlug && (
        <Redirect to={`/families/${currentUser.family_slug}`} />
      )}
      {updateSuccessRedirect && organisation && isPrimary(organisation.role) && (
        <Redirect to={`/${organisation.holding_slug}/${organisation.slug}/users/setup/success`} />
      )}
      {updateSuccessRedirect && organisation && !isPrimary(organisation.role) && (
        <Redirect to={Routes.organisationHomePath(organisation)} />
      )}
      {updateSuccessRedirect && holding?.organisations?.length > 0 && (
        <Redirect to={Routes.organisationHomePath(holding.organisations[0])} />
      )}
      {updateSuccessRedirect && holding?.organisations?.length === 0 && (
        <Redirect to={`/${holding.slug}/leads`} />
      )}
    </Grid>
  );
};

export default UserSetup;
