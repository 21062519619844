import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import UserSettings from '../../Pages/UserSetup/UserSettings';
// import MembershipLogin from '../Organisations/Membership/MembershipLogin';
import InviteMemberByEmail from '../Organisations/Membership/InviteMemberByEmail';
import OrganisationMembershipActions from '../../Redux/OrganisationMembershipRedux';
import ResetMemberPin from '../Organisations/Membership/ResetMemberPin';
import HelperContactDetails from '../Shared/HelperContactDetails';

const useStyles = makeStyles(theme => ({
  userInfo: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  edit: {
    color: 'initial',
    marginLeft: theme.spacing(2),
  },
  list: {
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '3px',
    minWidth: '40px',
    overflowWrap: 'break-word',
  },
}));

type Props = {
  user: Object,
  userMembership: Object,
  canEditUser: Boolean,
  canEditMembership: Boolean,
  canResetPin: Boolean,
  showEdit: Boolean,
};

const UserDescriptionCard = ({
  user,
  userMembership,
  canEditUser,
  canEditMembership,
  canResetPin,
  showEdit,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showEditUserSettings, setShowEditUserSettings] = React.useState(showEdit);
  const [showInviteByEmail, setShowInviteByEmail] = React.useState(false);
  const [showResetPin, setShowResetPin] = React.useState(false);
  // const passwordReset = React.useRef();
  // const updatedMembership = useSelector(state => state.organisationMembership.updateMembership);
  // const showMembershipLogin =
  //   updatedMembership && !updatedMembership?.helper?.email && passwordReset.current;

  // const onResetPassword = membership => {
  //   passwordReset.current = Math.floor(1000 + Math.random() * 9000).toString();
  //   const updateAttributes = {
  //     id: membership?.id,
  //     password: passwordReset.current,
  //   };
  //   dispatch(
  //     OrganisationMembershipActions.updateOrganisationMembershipRequest(
  //       membership?.holding_slug,
  //       membership?.organisation_slug,
  //       membership?.helper_id,
  //       updateAttributes,
  //     ),
  //   );
  //   setAnchorEl(null);
  // };

  // const onClose = () => {
  //   dispatch(OrganisationMembershipActions.resetNewMembership());
  // };

  const handleClickInviteByEmail = () => {
    if (!userMembership?.helper?.email) {
      setShowInviteByEmail(true);
    } else {
      dispatch(
        OrganisationMembershipActions.resendInvitationRequest(
          userMembership?.holding_slug,
          userMembership?.organisation_slug,
          userMembership?.helper_id,
        ),
      );
    }
    setAnchorEl(null);
  };

  const onResetPin = () => {
    setShowResetPin(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Paper className={classes.userInfo}>
        <Grid container className={classes.header}>
          {user && (
            <Typography variant="h4" component="h2" style={{ textTransform: 'capitalize' }}>
              {`${user?.first_name} ${user?.last_name?.toUpperCase()}`}
            </Typography>
          )}
          {user && canEditUser && (
            <Grid>
              <IconButton className={classes.edit} onClick={() => setShowEditUserSettings(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
              {canEditMembership && (
                <>
                  <IconButton
                    onClick={event => setAnchorEl(event.currentTarget)}
                    aria-label="More"
                    aria-owns={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    {/* <MenuItem
                      disabled={user?.email}
                      onClick={() => onResetPassword(userMembership)}
                    >
                      <ListItemText>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PASSWORD')}
                      </ListItemText>
                    </MenuItem> */}
                    {canResetPin && (
                      <MenuItem onClick={onResetPin}>
                        <ListItemText>
                          {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PIN')}
                        </ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem
                      disabled={user?.email && user?.validation_status === 'email_validated'}
                      onClick={handleClickInviteByEmail}
                    >
                      <ListItemText>
                        {t(
                          `APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.${
                            user?.validation_status === 'not_invited' ? 'EMAIL' : 'EMAIL_AGAIN'
                          }`,
                        )}
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          )}
        </Grid>
        <Typography
          variant="body2"
          component="div"
          paragraph
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: user?.description }}
        />
        <HelperContactDetails helper={user} />
      </Paper>
      <Dialog open={showEditUserSettings} onClose={() => setShowEditUserSettings(false)} fullWidth>
        <UserSettings userToEdit={user} onClose={() => setShowEditUserSettings(false)} />
      </Dialog>
      {/* <Dialog open={showMembershipLogin}>
        <MembershipLogin
          password={passwordReset.current}
          updatedMembership={updatedMembership}
          onClose={() => onClose()}
        />
      </Dialog> */}
      <Dialog open={showResetPin} onClose={() => setShowResetPin(false)}>
        <ResetMemberPin
          memberToResetPin={userMembership}
          onCloseDialog={() => setShowResetPin(false)}
        />
      </Dialog>
      <Dialog open={showInviteByEmail} onClose={() => setShowInviteByEmail(false)}>
        <InviteMemberByEmail
          memberToAddEmail={userMembership}
          onCloseDialog={() => setShowInviteByEmail(false)}
        />
      </Dialog>
    </>
  );
};

export default UserDescriptionCard;
