import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Badge, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  cell: {
    padding: theme.spacing(0.5, 1),
  },
  filterIcon: {
    color: grey[600],
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

type Props = {
  onClickAway: Function,
  onClick: Function,
  label: String,
  justifyContent: String,
  children: Object,
  hasSelectedFilters: Boolean,
};

const FilterTableCell = ({
  onClickAway,
  onClick,
  label,
  justifyContent,
  children,
  hasSelectedFilters,
}: Props) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.cell}>
      <Grid container alignItems="center" justifyContent={justifyContent || 'center'} wrap="nowrap">
        <ClickAwayListener onClickAway={onClickAway}>
          <Button
            startIcon={
              <Badge variant="dot" invisible={!hasSelectedFilters} color="primary">
                <FilterListIcon fontSize="small" className={classes.filterIcon} />
              </Badge>
            }
            onClick={onClick}
            className={classes.button}
          >
            <Typography variant="h6" component="p">
              {label}
            </Typography>
          </Button>
        </ClickAwayListener>
        {children}
      </Grid>
    </TableCell>
  );
};

export default FilterTableCell;
