import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import CoursesActions from '../../Redux/CoursesRedux';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import EmptyList from '../../Components/Shared/EmptyList';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import CardCourse from '../../Components/Shared/Courses/CardCourse';

type Props = {
  family: Object,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    marginTop: theme.spacing(-1),
  },
  paper: {
    margin: theme.spacing(2, 0),
  },
}));

const Courses = ({ family }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const courses = useSelector(state => state.courses.courses);
  const isFetching = useSelector(state => state.courses.isFetching.getFamilyCourses);
  const coursesFetch = !isFetching && courses;

  React.useEffect(() => {
    dispatch(CoursesActions.getFamilyCoursesRequest(family?.admin?.id));
  }, []);

  return (
    <OrganisationLayoutContainer menu={<AuthMenu family={family} />}>
      <div className={classes.appBarContainer}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Typography className={classes.grow} variant="h5" component="h2">
              {t('APP.FAMILY.COURSES.APP_BAR.TITLE')}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>

      {isFetching && <LinearProgress className={classes.progress} />}
      {coursesFetch &&
        courses.map(course => (
          <Paper key={course.id} className={classes.paper}>
            <CardCourse course={course} linkUrl={`/formations/${course.slug}`} />
          </Paper>
        ))}
      {!isFetching && courses && courses.length === 0 && (
        <EmptyList id="APP.FAMILY.COURSES.EMPTY" level="info" />
      )}
    </OrganisationLayoutContainer>
  );
};

export default Courses;
