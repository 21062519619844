import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import _uniq from 'lodash/uniq';

import { grey } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircle from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FilterListIcon from '@material-ui/icons/FilterList';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Checkbox, Dialog, FormControlLabel, ListItem, Popover, Switch } from '@material-ui/core';

import LeagueLayoutContainer from '../../../Components/Layout/LayoutContainer';
import AuthMenu from '../../../Components/Leagues/Menu';
import LeaguesActions from '../../../Redux/LeaguesRedux';
import RowHelper from './RowHelper';
import { stableSort } from '../../../Services/DataHelper';

import InviteMember from '../../Organisations/Settings/InviteMember';
import getLeagueOrganisationsWithoutNoStruct, {
  getHelpersStatus,
  getExistingLeagueLeadStatus,
} from '../../../Services/LeagueHelper';
import FilterTableCell from './FilterTableCell';
import { checkHostConstraint, HOSTS_NAMES } from '../../../Routing/HostConstraint';

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
    '&>*': {
      boxShadow: '0px 1px 3px 0.5px rgb(0 0 0 / 5%)',
      borderRadius: '4px',
    },
  },
  grow: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
  },
  tableContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cell: {
    padding: theme.spacing(0.5, 1),
  },
  filterIcon: {
    color: grey[600],
    paddingRight: theme.spacing(0.5),
  },
  switch: { marginRight: theme.spacing(3) },
  noFilter: { padding: theme.spacing(1) },
}));

const LeagueHelpers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { league_id: leagueId } = match.params;
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    lead: queryParam.get('lead'),
    orga: queryParam.get('orga'),
    status: queryParam.get('status'),
    leagueLead: queryParam.get('league_lead'),
    orgaLead: queryParam.get('orga_lead'),
  };
  const leadFrom = urlParams.lead?.split('|');
  const orgaFilters = urlParams.orga?.split('|');
  const statusFilters = urlParams.status?.split('|');
  const leagueLeadFilters = urlParams.leagueLead?.split('|');
  const orgaLeadFilters = urlParams.orgaLead?.split('|');

  const league = useSelector(state => state.leagues.league);
  const isFetchingLeague = useSelector(state => state.leagues.isFetching.getLeague);
  const isFetchingLeagueHelpers = useSelector(state => state.leagues.isFetching.getLeagueHelpers);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const leagueOrganisationsWithoutNoStruct = getLeagueOrganisationsWithoutNoStruct(league);
  const leagueOrganisations = leagueOrganisationsWithoutNoStruct
    .sort((a, b) => a.holding_name.localeCompare(b.holding_name))
    ?.slice();
  leagueOrganisations?.unshift({
    id: 0,
    holding_name: t(
      `APP.${league?.slug?.toUpperCase()}.LEAGUES.SETTINGS.WITHOUT_HOLDING`,
      t(`APP.LEAGUES.SETTINGS.WITHOUT_HOLDING`),
    ),
  });
  const leagueHelpers = useSelector(state => state.leagues.helpers);
  const helpersStatus = getHelpersStatus(leagueHelpers);

  const existingLeagueLeadStatus = getExistingLeagueLeadStatus(leagueHelpers);

  const existingOrgaLeadStatus = [
    t('APP.LEADS.LEAGUE.STATUS.new'),
    t('APP.LEADS.LEAGUE.STATUS.validated'),
  ];

  const helpersLoaded =
    leagueHelpers &&
    leagueHelpers.length > 0 &&
    league &&
    leagueHelpers[0].current_league_id === league.id;

  const showPagination = leagueHelpers?.length > 100;
  const [openNewHelperDialog, setOpenNewHelperDialog] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(
    checkHostConstraint(HOSTS_NAMES.mormal) ? 'departure_date' : 'lead_date',
  );
  const [showStatusFilter, setShowStatusFilter] = React.useState(null);
  const [showOrgaFilter, setShowOrgaFilter] = React.useState(null);
  const [showLeagueLeadFilter, setShowLeagueLeadFilter] = React.useState(null);
  const [showOrgaLeadFilter, setShowOrgaLeadFilter] = React.useState(null);
  const [filters, setFilters] = React.useState({
    lead: leadFrom || [],
    orga: orgaFilters || [],
    status: statusFilters || [],
    league_lead: leagueLeadFilters || [],
    orga_lead: orgaLeadFilters || [],
  });

  const getHoldingNames = holdingIds => {
    if (!leagueOrganisations) return '';

    return holdingIds.reduce((acc, item) => {
      const name = leagueOrganisations?.find(o => o.holding_id == item)?.holding_name || '';
      if (acc.length === 0) {
        return name;
      }
      return `${acc}, ${name}`;
    }, '');
  };

  const getHoldingIds = orgasIds => {
    if (!leagueOrganisations) return [];
    const holdingIds =
      orgasIds
        ?.map(orgaId => leagueOrganisations?.find(o => o.id == orgaId)?.holding_id)
        ?.filter(o => o && o != undefined) || [];

    return holdingIds ? _uniq(holdingIds) : [];
  };

  const leagueHelpersFormatted = React.useMemo(() => {
    if (!leagueHelpers) return [];

    const helpersWithReversedName = leagueHelpers
      .map(h => ({
        ...h,
        fullname: `${h.last_name} ${h.first_name}`,
      }))
      ?.sort((a, b) => a.fullname?.toLowerCase().localeCompare(b.fullname?.toLowerCase()));

    const helpersWithHolding = helpersWithReversedName.map(h => ({
      ...h,
      holdingsIds: getHoldingIds(h.organisations_ids),
    }));
    return helpersWithHolding.map(h => ({
      ...h,
      orgas: getHoldingNames(h.holdingsIds),
      departure_date: h?.last_lead?.departure_date,
      lead_date: h?.last_lead?.created_at,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagueHelpers, leagueOrganisations]);

  const leagueHelpersFiltered = React.useMemo(() => {
    if (!leagueHelpersFormatted) return [];

    let filtered = leagueHelpersFormatted;

    filtered = leadFrom?.includes('league')
      ? filtered.filter(h =>
          ['new', 'pending', 'validated', 'declined'].includes(h.last_lead?.league_status),
        )
      : filtered;

    const getOrgaHelpersfiltered = () => {
      let orgaHelpersfiltered = filtered.filter(helper => {
        return orgaFilters?.find(orgaId => {
          return helper.organisations_ids?.includes(parseInt(orgaId, 10));
        });
      });
      let helpersWithoutOrga = [];
      if (orgaFilters?.includes(0)) {
        helpersWithoutOrga = filtered.filter(helper => helper.holdingsIds?.length === 0);
      }
      orgaHelpersfiltered = orgaHelpersfiltered.concat(helpersWithoutOrga);
      return orgaHelpersfiltered;
    };
    filtered = orgaFilters ? getOrgaHelpersfiltered() : filtered;

    filtered = filterName
      ? filtered?.filter(h => h?.fullname?.toLowerCase().includes(filterName.toLowerCase()))
      : filtered;

    filtered = statusFilters
      ? filtered?.filter(h => statusFilters?.includes(h?.league_status))
      : filtered;

    filtered = leagueLeadFilters
      ? filtered?.filter(h => leagueLeadFilters?.includes(h?.last_lead?.league_status))
      : filtered;

    filtered = orgaLeadFilters
      ? filtered
          ?.filter(
            h =>
              h.last_lead &&
              (h?.last_lead?.league_status === 'transfered' ||
                h?.last_lead?.league_status === 'in_organisation'),
          )
          .filter(h => {
            if (orgaLeadFilters?.length === 2) return h;
            else if (orgaLeadFilters?.includes('Validé')) return h.last_lead?.responsible_id;
            else return !h.last_lead?.responsible_id;
          })
      : filtered;

    return filtered;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterName,
    orgaFilters,
    statusFilters,
    leagueLeadFilters,
    orgaLeadFilters,
    page,
    rowsPerPage,
    leagueHelpers,
    leagueOrganisations,
  ]);

  const leagueHelpersPaginated = React.useMemo(() => {
    const leagueHelpersSorted = stableSort(leagueHelpersFiltered, order, orderBy);
    const paginated =
      rowsPerPage === -1
        ? leagueHelpersSorted
        : leagueHelpersSorted?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return paginated;
  }, [page, rowsPerPage, leagueHelpersFiltered]);

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    dispatch(LeaguesActions.getLeagueHelpersRequest(leagueId));
    if (league?.id != leagueId || !leagueOrganisations || leagueOrganisations?.length <= 1)
      dispatch(LeaguesActions.getLeagueRequest(leagueId, true, false));
    // eslint-disable-next-line
  }, [leagueId]);

  const handleCreateHelper = (helperAttributes, _role, email) => {
    if (!helperAttributes) return;

    dispatch(LeaguesActions.createLeagueHelperRequest(leagueId, { ...helperAttributes, email }));
  };

  const handleFilterInputChange = (e, setFilterFunction) => {
    setFilterFunction(e);
    if (page != 0) setPage(0);
  };

  const getPath = () => {
    let path = `/leagues/${leagueId}/helpers`;
    const params = [];
    Object.keys(filters).map(filter => {
      const paramsString = filters[filter]?.join('|');
      if (paramsString) {
        params.push(`${filter}=${paramsString}`);
      }
    });
    return params.length > 0 ? path.concat('?').concat(params.join('&')) : path;
  };

  const handleFilterChange = (e, param, filterKey) => {
    let newFilters = filters;
    if (e) {
      newFilters[filterKey]?.push(param);
      setFilters(newFilters);
    } else {
      newFilters[filterKey] = filters[filterKey]?.filter(filterparam => filterparam !== param);
      setFilters(newFilters);
    }
    if (page != 0) setPage(0);
    history.push(getPath());
  };

  const renderListItem = (param, filterKey, label) => {
    return (
      <ListItem key={param}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters[filterKey]?.includes(param)}
              onChange={e => handleFilterChange(e.target.checked, param, filterKey)}
              color="primary"
            />
          }
          label={label}
        />
      </ListItem>
    );
  };

  return (
    <LeagueLayoutContainer menuWidth={1} menu={<AuthMenu league={league} activeItem="helpers" />}>
      <div className={classes.appBarContainer}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Typography className={classes.grow} variant="h5">
              {t('APP.LEAGUES.MENU.HELPERS')}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={leadFrom?.includes('league')}
                  onClick={() =>
                    handleFilterChange(!leadFrom?.includes('league'), 'league', 'lead')
                  }
                  color="primary"
                />
              }
              label={t('APP.LEAGUES.LEADS.ONLY_LEAGUE')}
              labelPlacement="start"
              className={classes.switch}
            />
            <Tooltip title={t('APP.LEAGUES.SETTINGS.NEW_HELPER')} placement="top">
              <IconButton onClick={() => setOpenNewHelperDialog(true)}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </div>
      {(isFetchingLeagueHelpers || isFetchingLeague) && <LinearProgress />}
      {league && leagueHelpers && helpersLoaded && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table aria-label="table" size="small" padding="none">
            {showPagination && (
              <TableHead>
                <TablePagination
                  rowsPerPageOptions={[50, 100, 200, 500, { label: 'Toutes', value: -1 }]}
                  count={leagueHelpersFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} à ${to} sur ${count !== -1 ? count : `Plus de ${to}`}`
                  }
                  labelRowsPerPage={t('APP.ORGANISATION.SETTINGS.MEMBERS.ROW_PER_PAGE')}
                  onPageChange={(event, newPage) => {
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  style={{ paddingBottom: 32 }}
                />
              </TableHead>
            )}

            {/* Filter row */}
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>
                  <Grid container alignItems="center" wrap="nowrap">
                    <FilterListIcon className={classes.filterIcon} />
                    <Input
                      placeholder={t('FILTER_BY_NAME')}
                      value={filterName}
                      onChange={e => handleFilterInputChange(e.target.value, setFilterName)}
                      disableUnderline
                      style={{ fontSize: '0.9rem' }}
                    />
                  </Grid>
                </TableCell>

                {/* orga */}
                <FilterTableCell
                  onClickAway={() => setShowOrgaFilter(null)}
                  onClick={event => setShowOrgaFilter(event.currentTarget)}
                  label={t('APP.LEAGUES.SETTINGS.HELPERS.COLUMN_ORGAS')}
                  justifyContent="flex-start"
                  hasSelectedFilters={orgaFilters?.length > 0}
                >
                  <Popover open={Boolean(showOrgaFilter)} anchorEl={showOrgaFilter}>
                    <Paper>
                      {leagueOrganisations.length > 0 ? (
                        leagueOrganisations.map(orga =>
                          renderListItem(`${orga.id}`, 'orga', orga.holding_name),
                        )
                      ) : (
                        <Typography className={classes.noFilter}>
                          {t('APP.LEAGUES.SETTINGS.HELPERS.NO_FILTER')}
                        </Typography>
                      )}
                    </Paper>
                  </Popover>
                </FilterTableCell>

                {/* status */}
                <FilterTableCell
                  onClickAway={() => setShowStatusFilter(null)}
                  onClick={event => setShowStatusFilter(event.currentTarget)}
                  label={t('APP.LEAGUES.SETTINGS.HELPERS.STATUS')}
                  hasSelectedFilters={statusFilters?.length > 0}
                >
                  <Popover open={Boolean(showStatusFilter)} anchorEl={showStatusFilter}>
                    <Paper>
                      {helpersStatus.length > 0 ? (
                        helpersStatus.map(status => renderListItem(status, 'status', status))
                      ) : (
                        <Typography className={classes.noFilter}>
                          {t('APP.LEAGUES.SETTINGS.HELPERS.NO_FILTER')}
                        </Typography>
                      )}
                    </Paper>
                  </Popover>
                </FilterTableCell>

                {/* league leads */}
                <FilterTableCell
                  onClickAway={() => setShowLeagueLeadFilter(null)}
                  onClick={event => setShowLeagueLeadFilter(event.currentTarget)}
                  label={t('APP.LEAGUES.SETTINGS.HELPERS.COLUMN_LEAGUE_LEAD')}
                  hasSelectedFilters={leagueLeadFilters?.length > 0}
                >
                  <Popover open={Boolean(showLeagueLeadFilter)} anchorEl={showLeagueLeadFilter}>
                    <Paper>
                      {existingLeagueLeadStatus.length > 0 ? (
                        existingLeagueLeadStatus.map(lead =>
                          renderListItem(lead, 'league_lead', t(`APP.LEADS.LEAGUE.STATUS.${lead}`)),
                        )
                      ) : (
                        <Typography className={classes.noFilter}>
                          {t('APP.LEAGUES.SETTINGS.HELPERS.NO_FILTER')}
                        </Typography>
                      )}
                    </Paper>
                  </Popover>
                </FilterTableCell>

                {/* orga leads */}
                <FilterTableCell
                  onClickAway={() => setShowOrgaLeadFilter(null)}
                  onClick={event => setShowOrgaLeadFilter(event.currentTarget)}
                  label={t('APP.LEAGUES.SETTINGS.HELPERS.COLUMN_ORGA_LEAD')}
                  hasSelectedFilters={orgaLeadFilters?.length > 0}
                >
                  <Popover open={Boolean(showOrgaLeadFilter)} anchorEl={showOrgaLeadFilter}>
                    <Paper>
                      {existingOrgaLeadStatus.map(lead => renderListItem(lead, 'orga_lead', lead))}
                    </Paper>
                  </Popover>
                </FilterTableCell>

                {/* date */}
                <TableCell
                  sortDirection={orderBy === 'lead_date' ? order : false}
                  className={classes.cell}
                >
                  <TableSortLabel
                    active={orderBy === 'lead_date'}
                    direction={orderBy === 'lead_date' ? order : 'asc'}
                    onClick={() => handleRequestSort('lead_date')}
                  >
                    <Typography variant="h6" gutterBottom component="p">
                      {t('APP.LEAGUES.SETTINGS.HELPERS.COLUMN_DATE')}
                    </Typography>
                  </TableSortLabel>
                </TableCell>

                {/* transport date */}
                {checkHostConstraint(HOSTS_NAMES.mormal) && (
                  <TableCell
                    sortDirection={orderBy === 'departure_date' ? order : false}
                    className={classes.cell}
                  >
                    <TableSortLabel
                      active={orderBy === 'departure_date'}
                      direction={orderBy === 'departure_date' ? order : 'asc'}
                      onClick={() => handleRequestSort('departure_date')}
                    >
                      <Typography variant="h6" gutterBottom component="p">
                        {t('APP.LEAGUES.SETTINGS.HELPERS.COLUMN_DEPARTURE_DATE')}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {leagueHelpersPaginated.map(helper => {
                return <RowHelper key={helper.id} helper={helper} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={openNewHelperDialog} onClose={() => setOpenNewHelperDialog(false)}>
        <InviteMember
          onClose={() => setOpenNewHelperDialog(false)}
          handleCreateOrgaMembership={handleCreateHelper}
          createMemberLabel="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.TITLE"
          titleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.TITLE"
          subtitleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.SUBTITLE"
          tooltipLabel="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.TOOLTIP3"
          showLeagueStatus
        />
      </Dialog>
    </LeagueLayoutContainer>
  );
};

export default LeagueHelpers;
