import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import CardActionArea from '@material-ui/core/CardActionArea';

import WelloAvatar from '../Shared/WelloAvatar';
import MembershipDetail from '../Organisations/Membership/MembershipDetail';
import OrganisationMembershipActions from '../../Redux/OrganisationMembershipRedux';
// import MembershipLogin from '../Organisations/Membership/MembershipLogin';
import InviteMemberByEmail from '../Organisations/Membership/InviteMemberByEmail';
import ResetMemberPin from '../Organisations/Membership/ResetMemberPin';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
    boxShadow: 'none',
  },
  cardActionArea: {
    width: '-webkit-fill-available',
  },
  memberName: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingBottom: 0,
  },
  avatarContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

type Props = {
  member: Object,
  family: Object,
  canConfigureFamily: Boolean,
};

const CardFamilyMember = ({ member, family, canConfigureFamily }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openUserDiag, setOpenUserDiag] = React.useState(false);
  const [showInviteByEmail, setShowInviteByEmail] = React.useState(false);
  const [memberToAddEmail, setMemberToAddEmail] = React.useState(null);
  const [showResetPin, setShowResetPin] = React.useState(false);
  const [memberToResetPin, setMemberToResetPin] = React.useState(null);
  // const password = React.useRef();
  // const updatedMembership = useSelector(state => state.organisationMembership.updateMembership);
  // const showMembershipLogin =
  //   updatedMembership && !updatedMembership.helper.email && password.current;

  // const onResetPassword = membership => {
  //   password.current = Math.floor(1000 + Math.random() * 9000).toString();
  //   const updateAttributes = {
  //     id: membership.id,
  //     password: password.current,
  //   };
  //   dispatch(
  //     OrganisationMembershipActions.updateOrganisationMembershipRequest(
  //       member.holding_slug,
  //       member.organisation_slug,
  //       membership.helper_id,
  //       updateAttributes,
  //     ),
  //   );
  //   setOpenUserDiag(false);
  // };

  const onResetPin = membership => {
    setShowResetPin(true);
    setMemberToResetPin(membership);
  };

  const onRemoveMember = membership => {
    dispatch(
      OrganisationMembershipActions.destroyOrganisationMembershipRequest(
        member.holding_slug,
        member.organisation_slug,
        membership.helper_id,
        membership.id,
      ),
    );
    setOpenUserDiag(false);
  };

  const onClickInviteWithEmail = membership => {
    setOpenUserDiag(false);

    if (!membership?.helper?.email) {
      setShowInviteByEmail(true);
      setMemberToAddEmail(membership);
    } else {
      dispatch(
        OrganisationMembershipActions.resendInvitationRequest(
          membership.holding_slug,
          membership.organisation_slug,
          membership.helper_id,
        ),
      );
    }
  };

  // const onClose = () => {
  //   dispatch(OrganisationMembershipActions.resetNewMembership());
  // };

  const onCloseDialog = () => {
    setShowInviteByEmail(false);
    setMemberToAddEmail(null);
  };

  const onClosePinDialog = () => {
    setShowResetPin(false);
    setOpenUserDiag(false);
    setMemberToResetPin(null);
  };

  return (
    <>
      <CardActionArea onClick={() => setOpenUserDiag(true)} className={classes.cardActionArea}>
        <Card className={classes.media}>
          <CardContent className={classes.content}>
            <Grid align="center" className={classes.avatarContent}>
              <WelloAvatar
                avatarUrl={member?.helper?.avatar_url}
                firstName={member?.helper?.first_name}
                lastName={member?.helper?.last_name}
                backgroundColor={member?.helper?.avatar_background_color}
                avatarStyle={{ height: 60, width: 60 }}
              />
            </Grid>
            <Grid className={classes.memberName}>
              <Typography variant="h5" component="h4" align="center" className={classes.capitalize}>
                {`${member?.helper?.first_name} ${member?.helper?.last_name?.toUpperCase()}`}
              </Typography>
            </Grid>
            <Typography variant="subtitle2" component="h5" align="center">
              {t(`APP.ROLE.VALIDATED.${member?.role?.toUpperCase()}`)}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
      <Dialog open={openUserDiag} onClose={() => setOpenUserDiag(false)}>
        <MembershipDetail
          membership={member}
          canConfigureFamily={canConfigureFamily}
          organisation={family}
          onRemoveMember={onRemoveMember}
          // onResetPassword={onResetPassword}
          onResetPin={onResetPin}
          onClickInviteWithEmail={onClickInviteWithEmail}
        />
      </Dialog>
      {/* <Dialog open={showMembershipLogin}>
        <MembershipLogin
          password={password.current}
          updatedMembership={updatedMembership}
          onClose={() => onClose()}
        />
      </Dialog> */}
      <Dialog open={showResetPin} onClose={onClosePinDialog}>
        <ResetMemberPin memberToResetPin={memberToResetPin} onCloseDialog={onClosePinDialog} />
      </Dialog>
      <Dialog open={showInviteByEmail} onClose={() => setShowInviteByEmail(false)}>
        <InviteMemberByEmail
          memberToAddEmail={memberToAddEmail}
          onCloseDialog={() => onCloseDialog()}
        />
      </Dialog>
    </>
  );
};
export default CardFamilyMember;
