import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import { CardActionArea } from '@material-ui/core';

import OrganisationMembershipActions from '../../Redux/OrganisationMembershipRedux';
import CardFamilyMember from './CardFamilyMember';
import InviteMember from '../../Pages/Organisations/Settings/InviteMember';
import UserActions from '../../Redux/UsersRedux';
import CardSliderSection from '..//Organisations/Shared/CardSliderSection';
import CardFamilyHome from './CardFamilyHome';

const useStyles = makeStyles(theme => ({
  section: {
    margin: theme.spacing(4, 0),
  },
  sectionTitle: {
    margin: theme.spacing(1),
  },
  slider: {
    '&>*': {
      margin: 0,
      '&>*': {
        margin: 0,
      },
    },
  },
  itemSlider: {
    padding: 8,
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  media: {
    objectFit: 'cover',
    boxShadow: 'none',
    height: 183,
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'dashed',
    borderColor: theme.wello.primary.main,
  },
  containerWithBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: 'solid 1px #0000001f',
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

type Props = {
  family: Object,
  members: Array,
  canConfigureFamily: Boolean,
  helpeds: Array,
  currentUserIsAdmin: Boolean,
};

const FamilyMembers = ({
  family,
  members,
  canConfigureFamily,
  helpeds,
  currentUserIsAdmin,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showInviteMemberDialog, setShowInviteMemberDialog] = React.useState(false);
  const helpedsLength = helpeds?.length;
  const hasHelpeds = helpedsLength > 0;
  const helpedsColumn = helpedsLength > 0 ? helpedsLength * 2 + 1 : 0;
  const isNotFlex = helpedsLength > 2 && members.length + helpedsLength > 5;
  const membersColumns = isNotFlex ? 5 : 5 - helpedsLength;

  const handleCreateOrgaMembership = (helper, role, email) => {
    dispatch(
      OrganisationMembershipActions.createOrganisationMembershipRequest(
        null,
        family.slug,
        {
          role,
          email: email ? email?.toLowerCase()?.trim() : null,
        },
        helper,
      ),
    );
  };

  const handleCreateHelped = helper => {
    dispatch(
      UserActions.createHelpedRequest(
        helper,
        helper.avatar && helper.avatar.size > 0 ? helper.avatar : null,
      ),
    );
  };

  return (
    <>
      <Grid className={classes.section}>
        <Grid container justifyContent="space-between">
          {(canConfigureFamily || members?.length > 0) && (
            <Typography variant="h4" component="h3" className={classes.sectionTitle}>
              {t('APP.ORGANISATION.SETTINGS.FAMILY_MEMBERS.TITLE')}
            </Typography>
          )}
          {canConfigureFamily && (
            <Button
              className={classes.button}
              color="primary"
              onClick={() => setShowInviteMemberDialog(true)}
            >
              {t('APP.ORGANISATION.SETTINGS.MEMBERS.ADD')}
            </Button>
          )}
        </Grid>

        <Grid container={!isNotFlex}>
          {/* Helpeds */}
          <Grid item xs={12} md={isNotFlex ? 12 : helpedsColumn}>
            {hasHelpeds && (
              <Grid className={!isNotFlex ? classes.containerWithBorder : null}>
                <CardSliderSection
                  slides={[
                    isNotFlex ? 5 : helpedsLength,
                    isNotFlex ? 4 : helpedsLength,
                    isNotFlex ? 3 : helpedsLength,
                    1,
                  ]}
                  isInfinite={helpedsLength > 3}
                >
                  {helpeds.map(fa => (
                    <Grid key={fa.id} className={classes.itemSlider}>
                      {currentUserIsAdmin ? (
                        <CardActionArea
                          component={Link}
                          to={`/families/${fa.slug}`}
                          className={classes.cardActionArea}
                        >
                          <CardFamilyHome family={fa} showFamiliesComponent />
                        </CardActionArea>
                      ) : (
                        <CardFamilyHome
                          family={fa}
                          showFamiliesComponent
                          canEditHelped={
                            family?.admin?.last_login_date === null &&
                            fa?.admin?.last_login_date === null
                          }
                          showDetails
                        />
                      )}
                    </Grid>
                  ))}
                </CardSliderSection>
              </Grid>
            )}
          </Grid>
          {/* Families */}
          <Grid item xs={12} md={isNotFlex ? 12 : 12 - helpedsColumn}>
            <CardSliderSection
              slides={[membersColumns, membersColumns - 1, 3, 1]}
              isInfinite={hasHelpeds && members?.length > membersColumns}
            >
              {members?.length > 0 &&
                members.map(member => (
                  <Grid key={member.id} className={classes.itemSlider}>
                    <CardFamilyMember
                      member={member}
                      family={family}
                      canConfigureFamily={canConfigureFamily}
                    />
                  </Grid>
                ))}
              {canConfigureFamily && members?.length < membersColumns && (
                <Grid className={classes.itemSlider}>
                  <Card className={classes.media}>
                    <Button
                      className={classes.button}
                      color="primary"
                      onClick={() => setShowInviteMemberDialog(true)}
                    >
                      <AddIcon />
                    </Button>
                  </Card>
                </Grid>
              )}
            </CardSliderSection>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={showInviteMemberDialog} onClose={() => setShowInviteMemberDialog(false)}>
        <InviteMember
          organisation={family}
          onClose={() => setShowInviteMemberDialog(false)}
          handleCreateOrgaMembership={handleCreateOrgaMembership}
          titleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.TITLE"
          subtitleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.SUBTITLE"
          hasRoleToSelect
          handleCreateHelped={handleCreateHelped}
        />
      </Dialog>
    </>
  );
};

export default FamilyMembers;
