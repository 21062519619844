import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import CardOrganisation from '../../Components/Organisations/Shared/CardOrganisation';
import { getRole, isAdmin, isMyFamily, isFamilyAdminLeagueAdmin } from '../../Acl/Controls';
import OrganisationMembershipActions from '../../Redux/OrganisationMembershipRedux';
import UserDescriptionCard from '../../Components/Families/UserDescriptionCard';
import FamilyMembers from '../../Components/Families/FamilyMembers';
import CardSliderSection from '../../Components/Organisations/Shared/CardSliderSection';
import { CheckRoleRule } from '../../Acl/Rules';
import CardCommunity from '../../Components/Communities/CardCommunity';

const useStyles = makeStyles(theme => ({
  minHeight: {
    minHeight: '71vh',
  },
  itemSlider: {
    '&>*': {
      margin: theme.spacing(1),
    },
  },
}));

type Props = {
  family: Object,
  organisation: Object,
};

const Home = ({ family, organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const { family_slug: familySlug } = match.params;
  const helpeds = family?.admin?.families?.filter(fa => !isMyFamily(fa));
  const members = useSelector(state => state.organisationMembership.members);
  const currentUser = useSelector(state => state.auth.currentUser);
  const currentUserIsLeagueAdmin = isFamilyAdminLeagueAdmin(currentUser, family);
  const role = getRole(currentUser, organisation?.holding_slug, organisation?.slug, familySlug);
  const parsedHash = new URLSearchParams(location.hash.substring(1));

  React.useEffect(() => {
    if (family && family.slug == familySlug)
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(null, familySlug, null),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familySlug, dispatch]);

  const membersFound =
    members && members.length !== 0 && members[0].organisation_slug === familySlug;
  const adminMembership = membersFound ? members?.find(member => isAdmin(member.role)) : null;

  return (
    <OrganisationLayoutContainer
      menu={<AuthMenu organisation={organisation} family={family} className={classes.minHeight} />}
    >
      <UserDescriptionCard
        showEdit={parsedHash.get('edit')}
        canEditUser={
          currentUserIsLeagueAdmin ||
          CheckRoleRule(role, 'organisation:configure', {
            organisation: family,
          })
        }
        canEditMembership={!isMyFamily(family)}
        userMembership={adminMembership}
        user={family?.admin}
        canResetPin={CheckRoleRule(role, 'pin:update')}
      />

      {/* Family Members */}
      {membersFound && (
        <FamilyMembers
          family={family}
          members={members?.filter(member => !isAdmin(member.role))}
          canConfigureFamily={CheckRoleRule(role, 'organisation:configure', {
            organisation: family,
          })}
          organisation={organisation}
          helpeds={helpeds}
          currentUserIsAdmin={currentUser?.id === family?.admin?.id}
        />
      )}

      {/* My structures */}
      {(family?.admin?.organisations?.length > 0 ||
        family?.admin?.pending_organisations?.length > 0) && (
        <CardSliderSection
          title={t('APP.USER.STRUCTURES', { context: isMyFamily(family) && 'MINE' })}
        >
          {family?.admin?.organisations?.map(orga => (
            <Grid key={orga.id} className={classes.itemSlider}>
              <CardOrganisation organisation={orga} />
            </Grid>
          ))}
          {family?.admin?.pending_organisations.map(pendingOrga => (
            <Grid key={pendingOrga.id} className={classes.itemSlider}>
              <CardOrganisation organisation={pendingOrga} />
              <Typography variant="subtitle2" component="p" align="center">
                {t('APP.USER.ORGANISATIONS.WAITING')}
              </Typography>
            </Grid>
          ))}
        </CardSliderSection>
      )}
      {/* My communities */}
      {(family?.admin?.communities?.length > 0 ||
        family?.admin?.pending_communities?.length > 0) && (
        <CardSliderSection
          title={t('APP.USER.COMMUNITIES', { context: isMyFamily(family) && 'MINE' })}
        >
          {family?.admin?.communities?.map(community => (
            <Grid key={community.id} className={classes.itemSlider}>
              <CardCommunity community={community} />
            </Grid>
          ))}
          {family?.admin?.pending_communities.map(pendingCommunity => (
            <Grid key={pendingCommunity.id} className={classes.itemSlider}>
              <CardCommunity community={pendingCommunity} />
              <Typography variant="subtitle2" component="p" align="center">
                {t('APP.USER.ORGANISATIONS.WAITING')}
              </Typography>
            </Grid>
          ))}
        </CardSliderSection>
      )}
    </OrganisationLayoutContainer>
  );
};

export default Home;
