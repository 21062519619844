import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClearIcon from '@material-ui/icons/Clear';

import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core/';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import LeadsActions from '../../Redux/LeadsRedux';

type Props = {
  family: Object,
  organisation: Object,
};

type RowProps = {
  lead: Object,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    transform: 'translateY(-8px)',
    marginBottom: '-4px',
  },
  transport: {
    padding: '16px',
  },
}));

const formatNumber = number => {
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

function Row({ lead }: RowProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const getLeadTotalDistance = lead => {
    return parseFloat(lead.meta?.volunteer_departure_distance || 0).toFixed(2);
  };

  const getLeadTotalAmount = lead => {
    return parseFloat(lead.meta?.partner_volunteer_refund_amount || 0).toFixed(2);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(lead.meta.departure_date).format('LLL')}</TableCell>
        <TableCell>
          Pour : {lead.helped ? lead.helped.fullname : lead.helper?.fullname}
          <br />
          De {lead.responsible?.formatted_address} à {lead.meta.departureFormattedAddress}
        </TableCell>
        <TableCell>{getLeadTotalDistance(lead)} km</TableCell>
        <TableCell>{getLeadTotalAmount(lead)} €</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={5}>
              <Typography variant="h6" gutterBottom component="div">
                Détail :
              </Typography>
              <List>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.ROUNDTRIP')} :{' '}
                  {lead.meta.round_trip ? t('YES') : t('NO')}
                </ListItem>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.HELPED_DISTANCE')} :{' '}
                  {parseFloat(lead.meta?.distance || 0)} km
                </ListItem>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.VOLUNTEER_DISTANCE')} :{' '}
                  {lead.meta?.volunteer_departure_distance
                    ? parseFloat(lead.meta?.volunteer_departure_distance).toFixed(2) + ' km'
                    : t('APP.FAMILY.TRANSPORT.VOLUNTEER_DISTANCE_NOT_CALCULATED')}
                </ListItem>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.AMOUNT')} :{' '}
                  {parseFloat(lead.meta?.estimatedPrice || 0).toFixed(2) + '€'}
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transport = ({ family, organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const leads = useSelector(state => state.leads.leads);
  const stats = useSelector(state => state.leads.stats);
  const totalLeads = useSelector(state => state.leads.totalleads);
  const isFetching = useSelector(state => state.leads.isFetching.getFamilyLeads);
  const currentYear = new Date().getFullYear();
  const months = moment.months();
  const years = [];
  const [pageFilters, setPageFilters] = React.useState({
    currentPage: 0,
    filterArchived: false,
    filterTransport: true,
    year: currentYear,
    month: moment().month() + 1,
  });

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    years.push(i);
  }

  const leadsFetch =
    !isFetching &&
    leads &&
    leads.length !== 0 &&
    (leads[0].helper?.id === family?.admin?.id ||
      leads[0].helped?.id === family?.admin?.id ||
      leads[0].responsible_id === family?.admin?.id);

  React.useEffect(() => {
    dispatch(
      LeadsActions.getFamilyLeadsRequest(
        family?.admin?.id,
        pageFilters.filterArchived,
        pageFilters.currentPage,
        pageFilters.filterTransport,
        pageFilters.year,
        pageFilters.month,
      ),
    );
  }, [dispatch, pageFilters]);

  return (
    <>
      <OrganisationLayoutContainer menu={<AuthMenu organisation={organisation} family={family} />}>
        <div className={classes.appBarContainer}>
          <AppBar position="static" color="inherit" elevation={1}>
            <Toolbar style={{ flexWrap: 'wrap' }}>
              <Typography className={classes.grow} variant="h5" component="h2">
                {t('APP.FAMILY.TRANSPORT.APP_BAR.TITLE')}
              </Typography>
              <FormGroup>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={pageFilters.filterArchived}
                        onClick={() =>
                          setPageFilters({
                            ...pageFilters,
                            currentPage: 0,
                            filterArchived: !pageFilters.filterArchived,
                          })
                        }
                        color="primary"
                      />
                    }
                    label={
                      pageFilters.filterArchived
                        ? t('APP.LEADS.FILTER.ARCHIVED')
                        : t('APP.LEADS.FILTER.NOT_ARCHIVED')
                    }
                  />
                </FormControl>
              </FormGroup>
            </Toolbar>
          </AppBar>
        </div>
        <Grid item xs={12}>
          <Paper>
            <Box p={2}>
              <Grid container justifyContent={'flex-start'} spacing={2}>
                <Grid item xs={3}>
                  <FormControl variant="outlined" component="fieldset" fullWidth>
                    <Select
                      value={pageFilters.year}
                      onChange={event =>
                        setPageFilters({
                          ...pageFilters,
                          year: event.target.value,
                        })
                      }
                      input={
                        <OutlinedInput labelWidth={0} name="visibility" id="select-visibilities" />
                      }
                      fullWidth
                      variant="outlined"
                    >
                      {years.map(year => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" component="fieldset" fullWidth>
                    <Select
                      endAdornment={
                        pageFilters.month && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setPageFilters({
                                  ...pageFilters,
                                  month: null,
                                })
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      value={pageFilters.month}
                      onChange={event =>
                        setPageFilters({
                          ...pageFilters,
                          month: event.target.value,
                        })
                      }
                      input={
                        <OutlinedInput labelWidth={0} name="visibility" id="select-visibilities" />
                      }
                      fullWidth
                      variant="outlined"
                    >
                      {months.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12}>
          {isFetching && <LinearProgress className={classes.progress} />}
          {leadsFetch && (
            <>
              <Box pt={2} pb={2}>
                <Paper>
                  <Grid container className={classes.transport}>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <b>Distance trajet domicile</b> :{' '}
                          {formatNumber(stats?.total_volunteer_distance)} km
                        </ListItem>
                        <ListItem>
                          <b>Moyenne trajet domicile</b> :{' '}
                          {formatNumber(stats?.average_volunteer_distance)} km
                        </ListItem>
                        <ListItem>
                          <b>Distance totale trajet bénéficiaire</b> :{' '}
                          {formatNumber(stats?.total_distance)} km
                        </ListItem>
                        <ListItem>
                          <b>Moyenne trajet bénéficiaire</b> :{' '}
                          {formatNumber(stats?.average_distance)} km
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <b>Coût des trajets</b> : {formatNumber(stats?.total_cost)} &euro;
                        </ListItem>
                        <ListItem>
                          <b>Montant total remboursé par MH</b> :{' '}
                          {formatNumber(stats?.volunteer_total_refund)} &euro;
                        </ListItem>
                        <ListItem>
                          <b>Montant moyen remboursé par MH</b> :{' '}
                          {formatNumber(stats?.volunteer_average_refund)} &euro;
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Paper>
                <div className={classes.transport}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.DATE')}</TableCell>
                          <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.DESCRIPTION')}</TableCell>
                          <TableCell>
                            {t('APP.FAMILY.TRANSPORT.HEADER.VOLUNTEER_DISTANCE')}
                          </TableCell>
                          <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.VOLUNTEER_AMOUNT')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leads.map((lead, index) => (
                          <Row key={index} lead={lead} />
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={false}
                            colSpan={6}
                            count={totalLeads}
                            rowsPerPage={30}
                            page={pageFilters.currentPage}
                            onPageChange={(event, page) =>
                              setPageFilters({ ...pageFilters, currentPage: page })
                            }
                            labelDisplayedRows={({ from, to, count }) =>
                              `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </>
          )}
        </Grid>
      </OrganisationLayoutContainer>
    </>
  );
};

export default Transport;
