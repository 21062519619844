import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import i18n from 'i18next';
import 'moment/locale/fr';
import AutoCompletePlaces from '../../Shared/AutoCompletePlaces';
import { getTripInfo, getTripPrice, isOpenedAtTransportDate } from '../../../Services/DataHelper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';

moment.locale(i18n?.language);

type Props = {
  lead: Object,
  setLead: Function,
  setTripPriceInfo: Function,
  openedAt: Object,
  service: Object,
};

type DateProps = {
  classes: Object,
  AdapterMoment: Object,
  lead: Object,
  checkDepartureDateTimeValidity: Function,
  handleChangeDepartureDate: Function,
  handleChangeDepartureTime: Function,
  isClosedAtDepatureTime: Function,
  isClosedAtReturnTime: Function,
  t: Function,
  handleRoundTrip: Function,
  handleReturnDate: Function,
  handleReturnTime: Function,
};

const useStyles = makeStyles(theme => ({
  dateLayout: {
    flexGrow: 1,
    alignItems: 'center',
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: '25px',
    height: '25px',
    display: 'inline-block',
  },
  duration: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  time: {
    backgroundColor: '#fff',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    fontStyle: 'italic',
  },
  reminder: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    color: 'green',
    lineHeight: '2',
  },
}));

const DateInputs = ({
  classes,
  AdapterMoment,
  lead,
  checkDepartureDateTimeValidity,
  handleChangeDepartureDate,
  handleChangeDepartureTime,
  isClosedAtDepatureTime,
  isClosedAtReturnTime,
  t,
  handleRoundTrip,
  handleReturnDate,
  handleReturnTime,
}: DateProps) => (
  <Grid container spacing={1}>
    <Grid container className={classes.dateLayout} spacing={3}>
      <Grid item lg={4} md={5} sm={5} xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={lead.departureDate}
            onChange={date => handleChangeDepartureDate(date)}
            label={t('APP.SERVICE.TEXT_FIELD.LABEL.DEPARTURE_DATE')}
            disablePast
            cancelText={t('CANCEL')}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                required
                margin="normal"
                error={!checkDepartureDateTimeValidity(lead.departureDate, lead.departureTime)}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={4} md={5} sm={5} xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            // ampm={false}
            value={lead.departureTime}
            minTime={lead.minDepartureTime}
            onChange={date => handleChangeDepartureTime(date)}
            label={t('START_TIME.LABEL')}
            cancelText={t('CANCEL')}
            minutesStep={5}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                required
                margin="normal"
                error={
                  isClosedAtDepatureTime ||
                  !checkDepartureDateTimeValidity(lead.departureDate, lead.departureTime)
                }
                helperText={
                  isClosedAtDepatureTime
                    ? t('APP.SERVICE.TEXT_FIELD.LABEL.HELPER_TEXT')
                    : !checkDepartureDateTimeValidity(lead.departureDate, lead.departureTime)
                    ? 'Date de départ minimum : ' +
                      lead.minDepartureDatetime.format('DD/MM/YYYY HH:mm:ss')
                    : null
                }
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        {/* return date and time */}
        <FormControl component="div">
          <FormControlLabel
            key={lead.roundTrip}
            label={t('APP.SERVICE.TEXT_FIELD.LABEL.ROUNDTRIP')}
            control={
              <Checkbox
                onClick={event => handleRoundTrip(event.target.checked)}
                checked={lead.roundTrip}
                name="roundTrip"
                color="primary"
              />
            }
          />
        </FormControl>
      </Grid>
    </Grid>
    {lead.roundTrip && (
      <Grid container className={classes.dateLayout} spacing={3}>
        <Grid item lg={4} md={5} sm={5} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={lead.returnDate}
              onChange={date => handleReturnDate(date)}
              label={t('APP.SERVICE.TEXT_FIELD.LABEL.RETURN_DATE')}
              disablePast
              cancelText={t('CANCEL')}
              minDate={lead.departureDate}
              renderInput={params => (
                <TextField {...params} variant="outlined" required margin="normal" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={4} md={5} sm={5} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              // ampm={false}
              value={lead.returnTime}
              onChange={date => handleReturnTime(date)}
              label={t('BACK_TIME.LABEL')}
              cancelText={t('CANCEL')}
              minutesStep={5}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  margin="normal"
                  error={isClosedAtReturnTime}
                  helperText={isClosedAtReturnTime && t('APP.SERVICE.TEXT_FIELD.LABEL.HELPER_TEXT')}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    )}
  </Grid>
);

const QuotationTransport = ({ lead, setLead, service, setTripPriceInfo, openedAt }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dateInputs, setDateInputs] = React.useState([]);

  const addDateInput = () => {
    if (dateInputs.length < 9) {
      setDateInputs([
        ...dateInputs,
        {
          departureDate: lead.departureDate,
          departureTime: lead.departureTime,
          returnDate: null,
          returnTime: null,
          minDepartureDatetime: lead.minDepartureDatetime,
        },
      ]);
    }
  };

  const updateDateInput = (index, key, value) => {
    setDateInputs(prevInputs =>
      prevInputs.map((input, i) => (i === index ? { ...input, [key]: value } : input)),
    );
  };

  const removeDateInput = index => {
    setDateInputs(dateInputs.filter((_, i) => i !== index));
  };

  const [departureFrom, setDepartureFrom] = React.useState(lead.departureFrom);
  const [arrivalTo, setArrivalTo] = React.useState(lead.arrivalTo);

  const isClosedAtDepatureTime =
    lead.departureTime &&
    !isOpenedAtTransportDate(lead.departureDate, lead.departureTime, openedAt);

  const isClosedAtReturnTime =
    lead.returnTime && !isOpenedAtTransportDate(lead.returnDate, lead.returnTime, openedAt);

  const handleChangeDepartureDate = date => {
    setLead({
      ...lead,
      departureDate: date,
      transportDateIsValid: checkDepartureDateTimeValidity(date, lead.departureTime),
    });
  };
  const handleChangeDepartureTime = date => {
    setLead({
      ...lead,
      departureTime: date,
      transportDateIsValid: checkDepartureDateTimeValidity(lead.departureDate, date),
    });
  };

  const checkDepartureDateTimeValidity = (date, time) => {
    if (typeof date === 'string') {
      date = moment(date);
    }
    if (typeof time === 'string') {
      time = moment(time);
    }
    if (date && time) {
      const combinedDate = date.clone().set({
        hour: time.hour(),
        minute: time.minute(),
        second: 0,
        millisecond: 0,
      });

      return combinedDate.isAfter(lead.minDepartureDatetime);
    }

    return false;
  };

  React.useEffect(() => {
    setLead({
      ...lead,
      cloneToDate: dateInputs,
    });
  }, [dateInputs]);

  React.useEffect(() => {
    getTripInfo(lead.departureFrom, lead.arrivalTo, lead.roundTrip).then(data => {
      const price = getTripPrice(service, data, lead.needAccompagnement, lead.duration);
      setTripPriceInfo({
        ...data,
        estimatedPrice: price,
        roundTrip: lead.roundTrip,
        needAccompagnement: lead.needAccompagnement,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  React.useEffect(() => {
    if (arrivalTo) {
      setLead({
        ...lead,
        arrivalTo: {
          formattedAddress: arrivalTo.formattedAddress,
          latitude: arrivalTo.latitude,
          longitude: arrivalTo.longitude,
          locality: arrivalTo.locality,
          postcode: arrivalTo.postcode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalTo]);

  React.useEffect(() => {
    if (departureFrom) {
      setLead({
        ...lead,
        departureFrom: {
          formattedAddress: departureFrom.formattedAddress,
          latitude: departureFrom.latitude,
          longitude: departureFrom.longitude,
          locality: departureFrom.locality,
          postcode: departureFrom.postcode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureFrom]);

  React.useEffect(() => {
    setLead({
      ...lead,
      transportDateIsValid: checkDepartureDateTimeValidity(
        moment(lead.departureDate),
        moment(lead.departureTime),
      ),
    });
  }, []);

  return (
    <>
      <div
        className={classes.reminder}
        dangerouslySetInnerHTML={{ __html: t('APP.QUOTATION.TRANSPORT.ADDRESS.REMINDER') }}
      ></div>
      <FormControl component="fieldset" className={classes.formControl} fullWidth required>
        <InputLabel
          variant="outlined"
          shrink
          className={classes.selectLabel}
          style={{
            zIndex: '1021',
          }}
        >
          {t('APP.SERVICE.TEXT_FIELD.LABEL.DEPARTURE_FROM')}
        </InputLabel>
        <AutoCompletePlaces
          onSelect={value => {
            setDepartureFrom({
              formattedAddress: value.formatted_address,
              latitude: value.coordinates?.lat,
              longitude: value.coordinates?.lng,
              locality: value.locality,
              postcode: value.postcode,
            });
          }}
          onClear={() => {
            setDepartureFrom({
              formattedAddress: '',
              latitude: null,
              longitude: null,
              locality: '',
              postcode: '',
            });
          }}
          placeHolder={t('APP.QUOTATION.TRANSPORT.ADDRESS.HELPER')}
          defaultValue={lead.departureFrom?.formattedAddress}
          id="departure"
          style={{ zIndex: 1020 }}
        />
      </FormControl>

      <FormControl component="fieldset" className={classes.formControl} fullWidth required>
        <InputLabel
          variant="outlined"
          shrink
          className={classes.selectLabel}
          style={{
            zIndex: '1011',
          }}
        >
          {t('APP.SERVICE.TEXT_FIELD.LABEL.ARRIVAL_TO')}
        </InputLabel>
        <AutoCompletePlaces
          onSelect={value => {
            setArrivalTo({
              formattedAddress: value.formatted_address,
              latitude: value.coordinates?.lat,
              longitude: value.coordinates?.lng,
              locality: value.locality,
              postcode: value.postcode,
            });
          }}
          onClear={() => {
            setArrivalTo({
              formattedAddress: '',
              latitude: null,
              longitude: null,
              locality: '',
              postcode: '',
            });
          }}
          placeHolder={t('APP.QUOTATION.TRANSPORT.ADDRESS.HELPER')}
          defaultValue={lead.arrivalTo?.formattedAddress}
          id="arrival"
        />
      </FormControl>

      {/* departure date and time */}
      <Grid container spacing={2}>
        <Grid item>
          <DateInputs
            t={t}
            lead={lead}
            setLead={setLead}
            classes={classes}
            checkDepartureDateTimeValidity={checkDepartureDateTimeValidity}
            AdapterMoment={AdapterMoment}
            isClosedAtDepatureTime={isClosedAtDepatureTime}
            isClosedAtReturnTime={isClosedAtReturnTime}
            handleChangeDepartureDate={handleChangeDepartureDate}
            handleChangeDepartureTime={handleChangeDepartureTime}
            handleReturnTime={returnTime => setLead({ ...lead, returnTime })}
            handleRoundTrip={roundTrip => setLead({ ...lead, roundTrip })}
            handleReturnDate={returnDate => setLead({ ...lead, returnDate })}
          />
        </Grid>
      </Grid>
      {dateInputs.length > 0 && (
        <Box pt={3} pb={3}>
          <Typography>Autres dates :</Typography>
        </Box>
      )}
      <Grid container direction={'row'} spacing={3}>
        {dateInputs.map((date, index) => (
          <Grid item>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={10}>
                <Paper elevation={3} variant={'outlined'}>
                  <Box p={3}>
                    <DateInputs
                      t={t}
                      lead={date}
                      classes={classes}
                      checkDepartureDateTimeValidity={checkDepartureDateTimeValidity}
                      AdapterMoment={AdapterMoment}
                      isClosedAtDepatureTime={isClosedAtDepatureTime}
                      isClosedAtReturnTime={isClosedAtReturnTime}
                      handleChangeDepartureDate={departureDate =>
                        updateDateInput(index, 'departureDate', departureDate)
                      }
                      handleChangeDepartureTime={departureTime =>
                        updateDateInput(index, 'departureTime', departureTime)
                      }
                      handleReturnTime={returnTime =>
                        updateDateInput(index, 'returnTime', returnTime)
                      }
                      handleRoundTrip={roundTrip => updateDateInput(index, 'roundTrip', roundTrip)}
                      handleReturnDate={returnDate =>
                        updateDateInput(index, 'returnDate', returnDate)
                      }
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <IconButton size={'small'} onClick={() => removeDateInput(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box pt={3}>
        <Button onClick={addDateInput} disabled={dateInputs.length >= 9}>
          Ajouter une date
        </Button>
      </Box>

      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        {/* reason */}
        <TextField
          id="description"
          name="description"
          value={lead.description}
          type="text"
          onChange={event => setLead({ ...lead, description: event.target.value })}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.REASON')}
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <TextField
          required
          id="passagerCount"
          name="passagerCount"
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.PASSAGER_COUNT')}
          value={lead.passagerCount}
          onChange={event => setLead({ ...lead, passagerCount: event.target.value })}
          type="number"
          inputProps={{ min: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="filled"
        />
        <FormControlLabel
          key={lead.needAccompagnement}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.NEED_ACCOMPAGNEMENT')}
          control={
            <Checkbox
              onClick={event =>
                setLead({
                  ...lead,
                  needAccompagnement: event.target.checked,
                  duration: event.target.checked ? '0.5' : null,
                })
              }
              checked={lead.needAccompagnement}
              name="needAccompagnement"
              color="primary"
            />
          }
        />
        {/* duration */}
        {lead.needAccompagnement && (
          <FormControl className={classes.formControl} margin="normal">
            <RadioGroup
              name="duration"
              value={lead.duration}
              onChange={event => setLead({ ...lead, duration: event.target.value })}
              required
            >
              <FormControlLabel
                value="0.5"
                control={<Radio color="primary" />}
                className={classes.duration}
                label={t('APP.QUOTATION.TRANSPORT.DURATION1.LABEL')}
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                className={classes.duration}
                label={t('APP.QUOTATION.TRANSPORT.DURATION2.LABEL')}
              />
              <FormControlLabel
                value="1.5"
                control={<Radio color="primary" />}
                className={classes.duration}
                label={t('APP.QUOTATION.TRANSPORT.DURATION3.LABEL')}
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                className={classes.duration}
                label={t('APP.QUOTATION.TRANSPORT.DURATION4.LABEL')}
              />
            </RadioGroup>
          </FormControl>
        )}
      </FormControl>
    </>
  );
};

export default QuotationTransport;
